import React, { useState, useCallback } from 'react'
import './styles.css'
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import SectionHeader from '../../headers/SectionHeader'

function Community({ ref }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const RenderIcon = useCallback(() => {
    if (succeeded === false) {
        return <CircularProgress style={{ color: "white"}} size={35}/>
    }
    return <FaCheckCircle className='checkbox' size={40} /> 
},[succeeded])

  function validateEmail(mail) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail?.match(validRegex)){
        return (true)
    }       
    setError(true);
    return (false)
}

function isInputEmpty() {
  if (email.length === 0) {
   setError(true);
  }
}

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }
  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const isEmailValid = validateEmail(email);
    if (isEmailValid === true){
        axios({
            method: 'post',
            url:"https://formspree.io/f/xknedaek",
            data: {email},
        }).then(() => {
            setSucceeded(true);
            setEmail('')
        }).catch((error) => {
          console.log(error);
            setError(true);
        })
    } else {
        setLoading(false);
        isInputEmpty();
        return null;
    }
  }
  return (
    <section ref={ref} className="community-container">
        <SectionHeader title={'Community'} color={'black'}/>
        <h3>Stay in touch</h3>
        <p>Subscribe to our newsletter and you'll get updates on our latest activities, including information on our upcoming membership opportunities.</p>
        <div className={`form-newsletter-wrapper ${loading? 'newsletter-success-container' : 'input-container'}  `}>
          <form
            onSubmit={handleSubmit}
            action="https://formspree.io/f/xknedaek"
            method="POST" name="newsletter" className={`newsletter-form-wrapper ${ succeeded? 'fade-background' : ''}`} 
            style={{ alignItems: loading? 'center': null, justifyContent: loading ? 'center': null }}
          >
            { !loading? (
              <>
                <input className={`${error? 'error-input': ''}`} type={'email'} name='email' placeholder='Enter email here' value={email} onChange={handleChangeEmail} style={{ fontFamily: 'inherit'}}/>
                <button type='submit' className='arrow-button'>Subscribe <span className='arrow'></span></button>
              </> ) : (<RenderIcon />)
            }
          </form>
        </div>
    </section>
  )
}

export default Community