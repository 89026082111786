import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyCmyzXCi5y87YPAbLPUq9LJRXg63FmZXNk",
    authDomain: "gocab-e6d53.firebaseapp.com",
    databaseURL: "https://gocab-e6d53-default-rtdb.firebaseio.com",
    projectId: "gocab-e6d53",
    storageBucket: "gocab-e6d53.appspot.com",
    messagingSenderId: "870934065144",
    appId: "1:870934065144:web:fb09c39d447a0350e0300d",
    measurementId: "G-8H16TJP1J2"
  };

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);
