import React from 'react'
import MissionList from '../../lists/MissionList';
import "./styles.css";

const missions = [
  { num: '01', title: "Safety", body: "Safety is our number one priority and we are continually working on ways to make sure our members feel and travel safely" },
  { num: '02', title: "Make a difference", body: "Fighting back against impaired driving, and empowering the right to safe travel. We work hard to make sure our platform has a positive impact on these fronts."},
  { num: '03', title: "Community", body: "Bringing together like minded people that are willing to help those in need"}
]
function Body() {
  return (
    <div className='why-container'>
      <div className='vision-container'>
        <h2>Vision</h2>
        <h3>Make travel accessible and putting an end to impaired driving in our community</h3>
        <h4>We want to grow into a platform that brings awareness to impaired driving and equip our cities with the tools and technology needed to access alternative means of travel.</h4>
      </div>
      <div className='mission-container'>
        <h2>Mission</h2>
        {missions.map(mission => <MissionList index={mission.num} title={mission.title} body={mission.body} key={mission.num} />)}
      </div>
    </div>
  )
}

export default Body