import React, { useEffect, useState } from 'react'
import { HiMenuAlt3 } from 'react-icons/hi'
import './styles.css'
import { useAuthState } from '../../../providers/AuthProvider';
import { Avatar, Box, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const innerWidth = window.innerWidth;
const mobile = innerWidth < 768;

function HeaderContent({ handleMenuClick, isMenuActive }) {
  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = useState(mobile);
  const [screenWidth, setScreenWidth] = useState(innerWidth);
  const { isSignedIn } = useAuthState();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  // const HeaderItems = useCallback(() => {},[])

  async function handleClickSignIn(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await navigation("/login");
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    })
    if( screenWidth <= 768){
      setIsMobile(true)
    } else {
      setIsMobile(false);
    }
  },[screenWidth]);

  return (
    <div className='header-content-container'>
        <button className={ isMenuActive.value? "menu-clicked": "menu-container"} onClick={handleMenuClick}>
            <HiMenuAlt3 size={45} color={"white"}/>
        </button>
          <Box display={{ xs: 'none', sm: "flex"}}>
            {isSignedIn ?
              <Avatar /> :
              <Button
                onClick={handleClickSignIn}
                variant='contained'
                sx={{
                  paddingX: 2,
                  paddingY: 0,
                  borderRadius: 8,
                  minWidth: 82,
                  color: "black",
                  marginLeft: 2,
                  bgcolor: "whitesmoke",
                  fontWeight: "600",
                  fontFamily: "Montserrat Alternates",
                  textTransform: "initial",
                  ":hover": {
                    bgcolor: "lightgray",
                  }
                }}
              >
                {loading ? <CircularProgress size={25} color='inherit' /> : "Sign in" }
              </Button>
            }
          </Box>
    </div>
  )
}

export default HeaderContent