/* eslint-disable no-unused-vars */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, TableFooter, Container, TablePagination, Tooltip, IconButton } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { Paper } from '@mui/material'
import { ArrowForward, DriveEtaRounded } from '@mui/icons-material'

const DEFAULT_ROWS_PER_PAGE = 8;
const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = "name";

const TripTable = ({data, onClickDetailsButton }) => {
    const [order, setOrder] = useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [visibleData, setVisibleData] = useState(data);
    const [paddingHeight, setPaddingHeight] = useState(0);
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      function handleChangeTab(event, newValue) {
        setTab(newValue);
      }

      const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
        setVisibleData(() =>
            data.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
                ).sort(
                    getComparator(order,orderBy)
                    )
        );

        const numEmptyRows = newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - data.length) : 0;
        const newPaddingHeight = 53 * numEmptyRows;
        setPaddingHeight(newPaddingHeight);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [order, orderBy, rowsPerPage]
      );

      const handleChangeRowsPerPage = useCallback(
        (event) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);
            setPage(0);

            setVisibleData(() =>
                data.slice(
                    0 * updatedRowsPerPage,
                    0 * updatedRowsPerPage + updatedRowsPerPage,
                    ).sort(
                        getComparator(order,orderBy)
                        )
            );
            setPaddingHeight(0);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [order, orderBy],
      );

      useEffect(() => {
        setVisibleData(() =>
            data.slice(0,0 + DEFAULT_ROWS_PER_PAGE).sort(
                getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY))
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

  return (
    <Box>
        <TableContainer elevation={4} variant="outlined" component={Paper} sx={{ marginTop: 10, borderRadius: 7 }}>
            <Table sx={{ minWidth: 850 }} size='small'>
                <TableHead>
                <Typography variant='h6' fontWeight={600} marginLeft={2} marginY={2}>Active Orders</Typography>
                    <TableRow sx={{ bgcolor: "#f5f5f5"}}>
                        {["Type","Start","Destination","Status"].map((item,index) =>
                        (<TableCell key={index} sx={{ textTransform: "uppercase", fontWeight: 600, borderColor: "#e5e5e5"}}>{item}</TableCell>))
                        }
                        <TableCell sx={{ textTransform: "uppercase", fontWeight: 600, borderColor: "#e5e5e5"}}>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index} sx={{ padding: 2, }}>
                            <TableCell sx={{ display: "flex", alignItems: "center", minHeight: 60, borderColor: "#e5e5e5"}}>
                                <Box sx={{ bgcolor: "#e5e5e5", borderRadius: "50%", height: 40, width: 40, display: "flex", justifyContent: "center", alignItems:"center", marginRight: 1}}>
                                    <DriveEtaRounded />
                                </Box>
                            </TableCell>
                            <TableCell sx={{ marginY: 1, borderColor: "#e5e5e5"}}>{item.start.description}</TableCell>
                            <TableCell sx={{ marginY: 1, borderColor: "#e5e5e5"}}>{item.destination.description}</TableCell>
                            <TableCell sx={{ marginY: 1, borderColor: "#e5e5e5"}}>
                                <Box sx={{
                                    paddingX: 2,
                                    borderRadius: 5,
                                    width: "fit-content",
                                    fontWeight: 600,
                                    textTransform: "uppercase",
                                    color: ["pending","viewing"].includes(item.status) ? "gray" : ["accepted", "arrived", "en-route", "in-progress"].includes(item.status) ? "orange" : item.status === "canceled" ? "rgb(180, 35, 24)" : "green" ,
                                    bgcolor: ["pending","viewing"].includes(item.status) ? "lightgrey" : ["accepted", "arrived", "en-route", "in-progress"].includes(item.status) ? "lightyellow" : item.status === "canceled" ? "rgb(180, 35, 24, 0.3)" : "lightgreen"
                                }}>
                                    {
                                        ["pending","viewing"].includes(item.status) ?
                                            "Waiting for driver" :
                                        ["accepted", "arrived", "en-route", "in-progress"].includes(item.status) ?
                                            "In progress" : item.status
                                    }
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Details">
                                    <IconButton onClick={(event) => onClickDetailsButton(event,item)}>
                                        <ArrowForward />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {data.length === 0 &&
                    <TableFooter>
                        <Container>
                            <Typography variant='h5' textAlign={'flex-start'}>No Active Orders</Typography>
                        </Container>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
        <TablePagination
            component={'div'}
            rowsPerPageOptions={[8,16,24]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
  )
}

export default TripTable