import { GroupRounded } from '@mui/icons-material'
import { Box, Card, Grid, Typography } from '@mui/material'
import React from 'react'

const InfoCard = ({icon, value=35, label="TOTAL MEMBERS", iconContainerColor,}) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card variant='elevation' elevation={3} sx={{ padding: 2, borderRadius: 5}}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant='h7' fontWeight={'600'} color={"grey"}>{label}</Typography>
          { icon === null ? null : <Box sx={{
            borderRadius: 10,
            bgcolor: iconContainerColor ? iconContainerColor : "green",
            height: 50,
            width: 50,
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
            }}
          >
            {icon === null ? null : icon ? icon : <GroupRounded fontSize='large' sx={{color: "white"}}/>}
          </Box>}
        </Box>
        <Typography variant='h4' fontWeight={600}>{value}</Typography>
      </Card>
    </Grid>
  )
}

export default InfoCard