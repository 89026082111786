import React, { useCallback, useState } from 'react'
import SocialIcon from './SocialIcon'
import { useForm } from '@formspree/react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import "./styles.css"
import { FaCheckCircle } from 'react-icons/fa';

function ContactUs({ref}) {
    const [state] = useForm('xvoyeoyz');
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        message: ""
    });
    const [loading, setLoading] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState({
        name:false,
        email:false,
        message:false,
    });

    function handleChange(event){
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    }

    function clearValues() {
        setContactInfo({
            name: "",
            email: "",
            message: ""
        });
    };

    function validateEmail(mail) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (mail?.match(validRegex)){
            return (true)
        }
        setError(prevState => ({ ...prevState, email: true}));
        return (false)
    }

    const RenderIcon = useCallback(() => {
        if (succeeded === false) {
            return <CircularProgress style={{ color: "white"}}/>
        }
        return <FaCheckCircle className='checkbox' size={60} />
    },[succeeded])

    function isInputEmpty(){
        if(contactInfo.name.length === 0){
            setError( prevState => ({...prevState, name:true}))
        }
        if( contactInfo.message.length === 0) {
            setError(prevState => ({ ...prevState, message:true}))
        }
        if( contactInfo.email.length === 0) {
            setError(prevState => ({ ...prevState, email:true}))
        }
    }

  const handleOnSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const isEmailValid = validateEmail(contactInfo.email);
    if (isEmailValid === true && contactInfo.name?.length > 0 && contactInfo.message?.length > 0){
        axios({
            method: 'post',
            url:'https://formspree.io/f/xvoyeoyz',
            data: contactInfo,
        }).then(() => {
            setSucceeded(true);
            clearValues()
        }).catch((error) => {
            setError({
                message:true,
                name:true,
                email:true,   
            });
        })
    } else {
        setLoading(false);
        isInputEmpty();
        return null;
    }
  };
  return (
    <>
        <h2 className='h2' style={{ backgroundColor: 'white', marginTop: '90px'}}>Contact Us</h2>
        <div className='contact-container'>
            <form ref={ref} className={`contactinfo-container ${loading? 'success-container' : ''} ${succeeded? 'fade-background' : ''}`} name='contact' action='https://formspree.io/f/xvoyeoyz' method='POST' onSubmit={handleOnSubmit}>
                { !loading? (
                <>
                    <div className='user-container'>
                      <input className={`${error.name ? 'error-input' : 'input-container'}`} value={contactInfo.name} type={'text'} placeholder="Name" id='name' name='name'  onChange={handleChange}/>
                      <input className={`${error.email ? 'error-input' : 'input-container'}`} type={'email'} placeholder="Email" id='email' name='email' value={contactInfo.email} onChange={handleChange}/>
                  </div>
                  <textarea className={` message-input ${error.message ? 'error-input' : ''}`} placeholder='Message' id='message' name='message' value={contactInfo.message} onChange={handleChange} rows={20} cols={60} />
                  <button type='submit' className='submit-button' disabled={state.submitting}>Send Message</button>
                </>) :
                ( <RenderIcon />)
                }
            </form>
            <div className='info-container'>
                <h2> Get in Touch</h2>
                <p>We're always here to help, contact us if you have any questions.</p>
                <div style={{ margin: 45}} />
                <SocialIcon />
            </div>
        </div>
    </>
  )
}

export default ContactUs