import React from 'react'
import { FaArrowDown } from "react-icons/fa"
import "./styles.css"

function FloatingButton() {
  return (
    <button className='custom-button'>
        <FaArrowDown size={55} color={"white"} />
    </button>
  )
}

export default FloatingButton