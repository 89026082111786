import React from 'react'

function MemberCard() {
  return (
    <div className='membercard-container'>
        <h3 style={{ color: "whitesmoke"}}>10k+</h3>
        <p style={{ color: "whitesmoke"}}>Community</p>
    </div>
  )
}

export default MemberCard