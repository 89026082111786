import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { auth } from '../../api/firebase';
import Logo from '../sections/Header/Logo';
import { ArrowBack } from '@mui/icons-material';

export const Login = () => {
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required("Email is required"),
      password: Yup.string().max(255).required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        await signInWithEmailAndPassword(auth,values.email, values.password);
        navigation("/");
        setLoading(false);
      } catch(err){
        setLoading(false);
        switch (err.code) {
          case 'auth/password':
            alert("Wrong email/password entered");
            break;
          case 'auth/user-not-found':
            alert("Username not found");
            break;
          default:
            break;
        }
      }
    }
  });

  function handleMouseDownPassword(e) {
    e.preventDefault();
  }

  function handleClickShowPassword() {
    setShowPassword((val) => !val);
  }

  function handleClickLogo(e) {
    e.preventDefault();
    navigation("/");
  }
  return (
    <main>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
      <Box sx={{
        background: 'linear-gradient(to right, #912424, #381b1b)',
        paddingY: 1,
        paddingX: 2,
      }}>
        <Logo onClick={handleClickLogo} />
      </Box>
      <Box></Box>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          marginTop: {xs: 5, sm: 10 }
        }}
      >
            <Link href='/' underline='hover' sx={{ display: "flex", alignItems: "center", marginBottom: 2, color: "black", fontFamily: "Montserrat Alternates"}}>
                <ArrowBack sx={{ marginRight: 1, color: "black"}}/>
                Go Back Home
            </Link>
          <Stack
          >
            <Typography variant="h4" marginBottom={2} fontFamily={"Montserrat Alternates"}>
              Sign In
            </Typography>
          </Stack>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  color='primary'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  sx={{
                    fontFamily: "Montserrat Alternates",
                  }}
                />
                <FormControl
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  name="password"
                >
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    id='password'
                    type={showPassword ? "text": "password"}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Password"
                    sx={{
                      fontFamily: "Montserrat Alternates",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </IconButton>
                      </InputAdornment>
                      }
                  />
                  <FormHelperText>{formik.touched.password && formik.errors.password}</FormHelperText>
                </FormControl>
              </Stack>
              {formik.errors.submit && (
                <Typography
                  color="error"
                  sx={{ mt: 3 }}
                  variant="body2"
                >
                  {formik.errors.submit}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{
                  mt: 3,
                  fontFamily: "Montserrat Alternates" ,
                  borderRadius: 4,
                  color:"white",
                  fontWeight: "600"
                }}
                type="submit"
                variant="contained"
              >
                Continue
              </Button>
            </form>
            <Link href='/login/reset' sx={{ alignSelf: "center", marginTop: 10, cursor: "pointer"}}>forgot password</Link>
      </Container>
    </main>
  )
}
