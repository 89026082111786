import { Avatar, Box, IconButton, Tooltip, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material'
import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa';
import { auth } from '../../../api/firebase';
import { green } from '@mui/material/colors';
import { CameraAlt } from '@mui/icons-material';

function AccountMenu({ onClickSignOut, open, onClickMenu, onCloseMenu, onClickAvatar, anchorEl }) {

  return (
    <React.Fragment>
         <Box>
            <Tooltip title="Account Settings">
                <IconButton
                    onClick={onClickAvatar}
                    size='small'
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup = "true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar />
                </IconButton>
            </Tooltip>
        </Box>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={onCloseMenu}
            onClick={onClickMenu}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    bgcolor: green[50],
                    borderRadius: 5,
                    '& .MuiAvatar-root': {
                        padding: 5,
                        ml: -0.5,
                        mr: 1,
                      },
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom'}}
        >
            <Box marginX={1} bgcolor={'white'} p={2} borderRadius={5} display={'flex'} alignItems={'center'}>
                <Box position={'relative'}>
                    <Box
                        bgcolor={green[500]}
                        borderRadius={"50%"}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={60}
                        width={60}
                        marginRight={3}
                    >
                        {auth.currentUser.displayName.charAt(0)}
                    </Box>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            bottom:-10,
                            right:5 ,
                            zIndex: 10,
                            bgcolor: 'white',
                            borderRadius: "50%",
                            border: 2,
                            padding:1,
                            ":hover" : {
                                bgcolor: "whitesmoke"
                            },
                        }}
                    >
                        <CameraAlt fontSize='small' />
                    </IconButton>
                </Box>
                <Box>
                    <Typography fontWeight={500} fontSize={14}>{auth.currentUser.displayName}</Typography>
                    <Typography variant='body2'>{auth.currentUser.email}</Typography>
                </Box>
            </Box>
            <MenuItem>
            </MenuItem>
            <MenuItem onClick={onClickSignOut}>
                <ListItemIcon>
                    <FaSignOutAlt />
                </ListItemIcon>
                Sign Out
            </MenuItem>

        </Menu>
    </React.Fragment>
  )
}

export default AccountMenu