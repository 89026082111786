import React, { useEffect, useState } from 'react'
import { Container, Typography, CircularProgress, Grid, List } from '@mui/material'
import { GoogleMap, useLoadScript, Marker, } from '@react-google-maps/api'
import ExpandableFleetItem from '../components/lists/ExpandableFleetItem';
import { useData } from '../../../providers/DataProvider';

const KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const Map = () => {
    const { orders, drivers } = useData();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey:KEY,
    })
    const [activeDrivers, setActiveDrivers] = useState([]);
    const [activeFleetItem, setActiveFleetItem] = useState(null)

    useEffect(() => {
        let ordersFiltered = orders.filter((order) => order?.driver.length > 0);
        const onlineDrivers = ordersFiltered.map((item) => { return item.driver})
        setActiveDrivers(() =>
            drivers.filter(
                (driver) => onlineDrivers.includes(driver.driverId)
                ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[orders]);

    function handleOnClickFleetItem(index) {
        if (activeFleetItem === null || activeFleetItem !== index) {
            setActiveFleetItem(index);
        } else {
            setActiveFleetItem(null);
        }
    }

  return (
    <Container sx={{ display: "flex", flexDirection: "column" }} maxWidth='xl'>
        <Typography variant='h4' fontWeight={'600'}>
            Map
        </Typography>
        <Grid container marginTop={5} spacing={3}>
            <Grid item md={4}>
                <List>
                    {orders.map((item, index) => {
                        let driver = drivers.at(drivers.findIndex((val) => val.driverId === item?.driver))
                       return <ExpandableFleetItem
                                key={index}
                                index={index}
                                activeIndex={activeFleetItem}
                                start={item.start.description}
                                destination={item.destination.description}
                                status={item.status}
                                driverName={driver?.name}
                                onClick={handleOnClickFleetItem}
                            />
                        })}
                </List>
            </Grid>
            <Grid item xs={12} md={8} borderRadius={8}>
                {isLoaded ?
                    <GoogleMap
                        zoom={11}
                        center={{ lat:47.560539, lng:-52.71283}}
                        options={{
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            zoomControl: false,
                        }}
                        mapContainerStyle={{
                            height: 600,
                            width: "100%",
                            borderRadius: 16
                        }}
                    >
                        {activeDrivers.map((driver) => (
                            <Marker
                                key={driver.driverId}
                                position={{
                                    lat: driver.location.latitude,
                                    lng: driver.location.longitude
                                }}
                                clickable
                                icon={{
                                    url: require("../../../assets/car.png"),
                                }}
                            ></Marker>
                            ))
                        }
                    </GoogleMap>
                    :
                    <CircularProgress sx={{ alignSelf: "center"}} color='primary' />
                }
            </Grid>
        </Grid>
    </Container>
  )
}

export default Map