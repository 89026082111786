import React, { useState } from 'react'
import Header from '../components/Header'
import { Box, CssBaseline, Divider, Drawer, List, Toolbar, Typography , IconButton } from '@mui/material'
import Logo from '../../../components/sections/Header/Logo';
import DrawerMenuItem from '../components/lists/DrawerMenuItem';
import Overview from './Overview';
import Members from './Members';
import Map from './Map';

const drawerWidth = 240;

const route = ['Overview', "Members", "Map"];

export const Admin = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const container = window !== undefined ? () => window.document.body : undefined;

    function handleClickMenu(e) {
        e.preventDefault()
        setMobileOpen((bool) => !bool);
    }

    function handleCloseMenu(e) {
        e.preventDefault();
        setMobileOpen(false);
    }
    function handleClickRoute(route, index) {
        // const val = route.toLowerCase();
        setActiveIndex(index);
        setMobileOpen(false);
        // if (val === "overview") {
        //     navigation("/");
        // } else {
        //     navigation(`/${val}`);
        // }
    }

  return (
    <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <Header drawerWidth={drawerWidth} onClickMenu={handleClickMenu} />
        <Box
            component={"nav"}
            sx={{
                width: {md: drawerWidth},
                flexShrink: {md: 0 },
            }}
        >
            <Drawer
                variant='tempoarary'
                anchor='left'
                ModalProps={{ keepMounted: true }}
                container={container}
                open={mobileOpen}
                sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    background: 'linear-gradient(to right, #912424, #381b1b)',
                },
                }}
            >
                <Toolbar>
                    <Box sx={{ position: 'realtive', mt: 2, display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'space-between'}}>
                        <Logo size={20} />
                        <IconButton sx={{ alignSelf: "center" }} onClick={handleCloseMenu}>
                            <Typography variant='h5' fontWeight={'600'} color={'whitesmoke'}>X</Typography>
                        </IconButton>
                    </Box>
                </Toolbar>
                <Divider />
                <List sx={{ marginTop: 3 ,marginX: 0.5, display: 'flex', flexDirection: 'column', gap: 0.3 }}>
                    {route.map((item, index) => (<DrawerMenuItem onClick={handleClickRoute} key={index} index={index} activeIndex={activeIndex} label={item} />))}
                </List>
            </Drawer>
            <Drawer
                variant='permanent'
                anchor='left'
                open
                sx={{
                    display: {xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        background: 'linear-gradient(to right, #912424, #381b1b)',
                    },
                }}
            >
                <Toolbar>
                    <Box sx={{ position: 'realtive', mt: 2, display: 'flex', direction: 'column', width: "100%", flexDirection: 'column', alignItems: 'center'}}>
                        <Logo size={20} />
                    </Box>
                </Toolbar>
                <Divider />
                <List sx={{ marginTop: 3 ,marginX: 0.5, display: 'flex', flexDirection: 'column', gap: 0.3 }}>
                    {route.map((item, index) => (<DrawerMenuItem onClick={handleClickRoute} key={index} index={index} activeIndex={activeIndex} label={item} />))}
                </List>
            </Drawer>
        </Box>
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { xs: `calc(100% - ${drawerWidth}px)` } }}
        >
            <Toolbar />
            {activeIndex === 0 && <Overview onClickDetailsButton={()=> setActiveIndex(2)} />}
            {activeIndex === 1 && <Members />}
            {activeIndex === 2 && <Map />}
        </Box>
    </Box>
  )
}
