import React, { useState, useRef } from 'react'
import Banner from '../sections/Banner'
import Header from '../sections/Header'
import Body from '../sections/Body'
import './styles.css'
import Footer from '../sections/Footer'
import About from '../sections/About'
import Community from '../sections/Community'
import ContactUs from '../sections/ContactUs'
import { useAuthState } from '../../providers/AuthProvider'
import { Backdrop, CircularProgress } from '@mui/material'

function HomePage() {
  const [menuClicked, setMenuClicked] = useState({ value: false, style: ''});
  const { initializing } = useAuthState();
  const aboutRef = useRef();
  const visionRef = useRef();
  const communityRef = useRef();
  const contactUsRef = useRef();

  function handleMenuClick() {
    if (menuClicked.value === false) {
      setMenuClicked({ value:true, style: 'visible'});
    } else {
      setMenuClicked({ value:false, style: 'hidden'});
    }
  }

  const scrollParams = { behavior: "smooth" }

  const handleItemClick = (route) => {
    if (route === "vision") {
      visionRef.current?.scrollIntoView(scrollParams);
    }
    else if (route === "about") {
      aboutRef.current?.scrollIntoView(scrollParams);
    }
    else if ( route === "community") {
      communityRef.current?.scrollIntoView(scrollParams);
    }
    else if ( route === "contactUs") {
      contactUsRef.current?.scrollIntoView(scrollParams);
    } else {
      return null;
    }
  }
  return (
    <div className='home'>
      <Backdrop sx={{ bgColor: "white", zIndex: 0 }} open={initializing}>
        <CircularProgress size={60} />
      </Backdrop>
      <header>
        <Header handleMenuClick={handleMenuClick} isMenuActive={menuClicked} />
      </header>
      <section>
        <Banner isNavBarVisible={menuClicked} onNavItemClick={handleItemClick} setMenuClicked={setMenuClicked} handleCloseClick={handleMenuClick}/>
      </section>
      <section ref={visionRef}>
        <Body />
      </section>
      <section ref={aboutRef}>
        <About />
      </section>
      <section ref={communityRef}>
        <Community />
      </section>
      <section ref={contactUsRef} style={{ backgroundColor: 'white'}}>
        <ContactUs />
      </section>
        <Footer />
    </div>
  )
}

export default HomePage