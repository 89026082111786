import React from 'react'
import { motion } from 'framer-motion'
import './styles.css'

function AnimatedLogo() {
  return (
    <motion.div className='collab-container'>
        <motion.div className='collab-align-container'>
            <motion.img className='ddnl-logo' src={require('../../../assets/logo2.png')} style={{ width: "50%"}} alt={'ddnl logo'}></motion.img>
            <motion.div className='connecter'>+</motion.div>
            <motion.img className='gocabs-logo' src={require('../../../assets/GoCabsLogo.png')} alt={'gocabs logo'}></motion.img>
        </motion.div>
    </motion.div>
  )
}

export default AnimatedLogo