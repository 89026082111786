import React from 'react'
import './styles.css'

function Main() {
  return (
        <div className='name-container'>
            <div id='h1' className={'title-container-heading'}><p>Double d's<b className='white'>Nl</b></p></div>
            <div style={{ color: "whitesmoke"}} className={'subtitle-container'}> The largest designated driver community in the province</div>
        </div>
  );
}

export default Main