import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuthState } from "./AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebase";

const DatabaseContext = createContext(null);

export const DataProvider = ({children}) => {
    const { role } = useAuthState();
    const [drivers, setDrivers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedMember, setSelectedMember] = useState({});

    useEffect(() => {
        if (!["admin","moderator"].includes(role)) {
            return;
        }
        (async () => {
            const driversRef = collection(db,"drivers");
            const requestsRef = collection(db,"requests");
            const driverQuery = query(driversRef, where("type", "==", "DD"));
            const requestsQuery = query(requestsRef,
                    where("cabType", "==", "DD"),
                    where("status", "in", ["pending", "viewing", "accepted","en-route", "in-progress","arrived"])
                    );

            let driversArray = [];
            let requests = [];

            try {
                 await Promise.all([
                    getDocs(driverQuery).then((q) => {
                        q.forEach((data) => {
                            driversArray.push({
                                ...data.data(),
                                driverId: data.id,
                            });
                        });
                        setDrivers(driversArray);
                    }),
                    getDocs(requestsQuery).then((q) => {
                        q.forEach((data) => {
                            requests.push({
                                ...data.data(),
                                orderId: data.id,
                            });
                        });
                        setOrders(requests);
                    })]);
            }catch(err){

            }
        })();
    },[role]);
    return(
        <DatabaseContext.Provider value={{ drivers, orders, selectedMember, setSelectedMember}}>
            {children}
        </DatabaseContext.Provider>
    )
}

export const useData = () => useContext(DatabaseContext);
