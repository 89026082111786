import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Link, TextField, Typography } from '@mui/material'
import { sendPasswordResetEmail } from 'firebase/auth';
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from "yup";
import { auth } from '../../api/firebase';
import Logo from '../sections/Header/Logo';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        type: "",
        status: false,
    });
    const navigation = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        }),
        onSubmit: async (values, helpers) => {
            try {
                setLoading(true);
                await sendPasswordResetEmail(auth,values.email);
                setLoading(false);
                setAlert({
                    status: true,
                    type: "success"
                });
            } catch(err) {
                console.log(err);
                setAlert({ type: "error", status: true})
            }
        }
    });

    function handleClickLogo(e) {
        e.preventDefault();
        navigation("/");
    }
  return (
    <Box
        sx={{
            backgroundColor: "background.paper",
        }}
    >
        <Box sx={{
        background: 'linear-gradient(to right, #912424, #381b1b)',
        paddingY: 1,
        paddingX: 2,
      }}>
        <Logo size={30} onClick={handleClickLogo} />
      </Box>
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                marginTop: {xs: 5, sm: 10}
            }}
        >
            <Link href='/login' underline='hover' sx={{ display: "flex", alignItems: "center", marginBottom: 2, color: "black", fontFamily: "Montserrat Alternates"}}>
                <ArrowBack sx={{ marginRight: 1, color: "black"}}/>
                Back to Login
            </Link>
            <Typography variant='h4' marginBottom={2} fontFamily={"Montserrat Alternates"}>
                Forgot Password
            </Typography>
            { alert.status ?
                <Alert severity={alert.type}>
                    <AlertTitle>
                        {alert.type === "success" ?
                            "Reset Password Email Sent" :
                            "Error"
                        }
                    </AlertTitle>
                    { alert.type === "success" ?"We just sent an email containing a tempoarary link to reset your password. Please check your spam folder if the email doesn't appear within a few minutes." :
                        "Sorry, an error occured while attempting to reset your password. Please try again later after a few minutes"
                    }
                </Alert> :
                <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                >
                    <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    color="primary"
                    value={formik.values.email}
                    sx={{
                        fontFamily: "Montserrat Alternates",
                        borderRadius: 3

                    }}
                    />
                    <Button
                        fullWidth
                        disabled={loading}
                        size="large"
                        sx={{
                        mt: 3,
                        fontFamily: "Montserrat Alternates" ,
                        borderRadius: 4,
                        color:"white",
                        fontWeight: "600",
                        textTransform: "none",
                        }}
                        type="submit"
                        variant="contained"
                    >
                        {loading ? < CircularProgress size={25} /> : "Send reset link"}
                    </Button>
                </form>
                }
        </Container>
    </Box>
  )
}

export default ForgotPassword