import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Collapse, ListItem, Typography, } from '@mui/material'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Person } from '@mui/icons-material';

const DEFAULT_TIMELINE_ARRAY = ["Order Created", "accepted", "en-route", "arrived", "in-progress", "complete"]

const ExpandableFleetItem = ({index, activeIndex, onClick, start = "MUN", destination="Avalon Mall", status="in-progress", driverName  }) => {
    const [visibleTimeline, setVisibleTimeline] = useState(DEFAULT_TIMELINE_ARRAY);

    function handleClickItem(event) {
        event.preventDefault();
        onClick(index);
    }

    useEffect(() => {
        setVisibleTimeline(() =>
            DEFAULT_TIMELINE_ARRAY.slice(0, 1 + DEFAULT_TIMELINE_ARRAY.findIndex((val) => val === status)))
    },[status]);

  return (
    <ListItem>
        <Box
            width={'100%'}
            borderBottom={1}
            borderColor={"lightgray"}
        >
            <Box
                display={"flex"}
                alignItems={"center"}
                onClick={handleClickItem}
                sx={{
                    color: "black",
                    gap: 2,
                    borderBottom: index === activeIndex ? 1 : 0,
                    paddingBottom: 2,
                    borderColor: 'lightgray',
                    ":hover": {
                        cursor: "pointer",
                    }
                }}
            >
                <Box sx={{ height: 50, width: 50 }}>
                    { !["pending", "viewing"].includes(status) ?
                        <img src={require("../../../../assets/3d-car.png")} style={{ objectFit: "contain", width: "100%"}} alt='3d car icon'/>
                        :
                        <Person sx={{
                            width: "100%",
                            height: "100%",
                        }} />
                    }
                </Box>
                <Box>
                    {
                        !["pending", "viewing"].includes(status) ?
                            (driverName ?
                                driverName
                                :
                                <CircularProgress sx={{ alignSelf: "center", marginLeft: 3}} size={20} />
                            )
                            :
                            "Waiting for Driver"
                    }
                    <Typography variant='body1' fontSize={14} color={'gray'}>
                        from: {start}
                    </Typography>
                    <Typography fontSize={14} color={'gray'}>
                        to: {destination}
                    </Typography>
                </Box>
            </Box>
            <Collapse in={index === activeIndex} timeout={"auto"} unmountOnExit>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                >
                    {visibleTimeline.map((item, index) => {
                        return (
                            <TimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot color='primary' />
                                    {index < visibleTimeline.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent sx={{
                                    textTransform: "capitalize"
                                }}>
                                    {item}
                                    { item === "Order Created" &&
                                        <Typography
                                            variant='body2'
                                        >
                                            for 10:00am
                                        </Typography>
                                    }
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
            </Collapse>
        </Box>
    </ListItem>
  )
}

export default ExpandableFleetItem