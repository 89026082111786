import React from 'react'
import NavBar from '../Header/NavBar'
import AnimatedBanner from './animatedImage'
import FloatingButton from './FloatingButton'
import Main from './Main'
import MemberCard from './MemberCard'
import './styles.css'
import { useNavigate } from 'react-router-dom'

function Banner({ isNavBarVisible, onNavItemClick, setMenuClicked, handleCloseClick }) {
  const navigate = useNavigate();
  function handleClickSignIn() {
    navigate('/login');
  }
  return (
    <div className='banner-container'>
        <div className='section-1'>
            <Main/>
            <NavBar
              onClickItem={onNavItemClick}
              visible={isNavBarVisible}
              setMenuClicked={setMenuClicked}
              handleClick={handleCloseClick}
              onClickSignIn={handleClickSignIn}
            />
        </div>
        <div style={{margin: 35}}/>
        <div style={{margin: 45}}/>
        <div className='button-banner-container'>
          <FloatingButton/>
          <AnimatedBanner />
        </div>
        <div className='section-2'>
            <div className='slogan-container'>
                <h3>Creating a safe and reliable community where we support and help each other travel safely.</h3>
            </div>
            <MemberCard />
        </div>
    </div>
  )
}

export default Banner