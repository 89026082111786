import React from 'react'
import './styles.css'

function NavBarItems(props) {

  const route = props.route;

  function onClickHandler(event) {
    event.preventDefault();
    props.onClick(route);
    props.setMenuClicked({ value:false, style: ''});
  }

  return (
    <>
      { props.visible? (
        <li className={"navbar-item animation"} onClick={onClickHandler}>
            <p><b>{`0${props.id}`}</b>{props.title}</p>
            <div className='underline'/>
        </li>) : null
      }
    </>
  )
}

export default NavBarItems