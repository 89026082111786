import React from 'react'
import SectionHeader from '../../headers/SectionHeader'
import './styles.css'
import { motion } from 'framer-motion'
import SpringContent from './SpringContent'
import SpringImage from './SpringImage'
import AnimatedLogo from './AnimatedLogo'

function About({ ref }) {
  return (
    <motion.div ref={ref} className={"about-container"} >
      <div className='red-background'>
        <SectionHeader title={'Our Story'} color={'white'}/>
        <motion.section initial='hidden' whileInView='onscreen' viewport={{ once: true, amount: 0.8}}>
            <SpringContent 
              title={2015}
              alignTitle={'flex-end'}
              titleColor={'#fefe14'}
              bodyColor={'white'} 
              body={'Founded in 2015 by Isabella White, DDNL started as a facebook group, it was a way of bringing the community together to help against drunk driving.'} 
            >
              <SpringImage/>
            </SpringContent>
        </motion.section>
        <motion.section initial='hidden' whileInView='onscreen' viewport={{ once: true, amount: 0.8}}>
          <SpringContent 
            title={'2017 - 2020'}
            titleColor={'#fefe14'}
            bodyColor={'white'} 
            body={'During this time we welcomed two new members to our team, Robert and Gabrielle, people that understood and shared our vision. With their hardwork our facebook group grew to over 7 thousand members, we truly established ourselfves as an alternative solution for people that could not afford taxi services and needed to travel safely'} 
          />
        </motion.section>
        <motion.section initial='hidden' whileInView='onscreen' viewport={{ once: true, amount: 0.8}}>
          <SpringContent
            title={2022}
            alignTitle={'flex-end'}
            titleColor={'#2fb63f'}
            bodyColor={'white'}
            body={'At the start of the year we hit a huge milestone of 10K members. We remained dedicated to our vision, operating as a not for profit platform.'}
          />
        </motion.section>
        <motion.section initial='hidden' whileInView='onscreen' viewport={{ once: true, amount: 0.8}}>
          <SpringContent 
            title={'Now'}
            alignTitle={'flex-start'}  
            titleColor={'#2fb63f'} 
            bodyColor={'white'} 
            body={' We have established a partnership with GoCabs and began development of a dedicated platform, to better serve our members'} 
          >
            <AnimatedLogo/>
          </SpringContent>
        </motion.section>
      </div>
    </motion.div>
  )
}

export default About