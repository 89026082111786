import React from 'react'
import { Container, Typography, Grid } from '@mui/material'
import InfoCard from '../components/cards/InfoCard'
import OnlinePulse from '../components/misc/OnlinePulse'
import { UpdateRounded, DriveEtaRounded } from '@mui/icons-material'
import TripTable from '../components/tables/TripTable'
import { useData } from '../../../providers/DataProvider'

const Overview = ({ onClickDetailsButton}) => {
    const { orders, drivers } = useData();
    const driversOnline = drivers.map((item,index) => {
        if (item.online === true) {
            return item;
        } else {
            return null;
        }
    });

  return (
    <Container maxWidth='xl'>
            <Typography variant='h4' fontWeight={'600'}>
                Overview
            </Typography>
            <Grid container spacing={2} marginTop={2}>
                <InfoCard value={"10k"} />
                <InfoCard
                    label='DRIVERS ONLINE'
                    icon={<OnlinePulse />}
                    iconContainerColor={'white'}
                    value={driversOnline.filter((item) => item !== null).length}
                />
                <InfoCard
                    label='TRIPS COMPLETED TODAY'
                    value={0}
                    icon={<DriveEtaRounded sx={{ color: "white" }} fontSize='large' />}
                />
                <InfoCard
                    label='ACTIVE TRIPS'
                    value={0}
                    icon={<UpdateRounded sx={{ color: "white" }} fontSize='large'/>}
                />
            </Grid>
            <TripTable data={orders} onClickDetailsButton={onClickDetailsButton}/>
    </Container>
  )
}

export default Overview