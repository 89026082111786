import React from 'react'
import { motion } from 'framer-motion'
import './styles.css'

const storyVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9
},
onscreen: {
    opacity:1,
    scale: 1,
    transition: {
        duration:0.5,
    }
}
}

function SpringContent({ title, body, titleColor, bodyColor, alignTitle, rowDirection, children }) {
  return (
    <motion.div className='story-message-container' variants={storyVariants}>
        <motion.h3 style={{ color: titleColor, alignSelf: alignTitle}}>{title}</motion.h3>
        <motion.div className={`story-container ${rowDirection}`}>
          {children}
          <motion.p style={{ color: bodyColor}}>{body}</motion.p>
        </motion.div>  
    </motion.div>
  )
}

export default SpringContent