import React from 'react'
import './styles.css'

function SectionHeader({title, color}) {
  return (
    <div className='section-header-container'>
        <div className={`line-spacer`} style={{ backgroundColor: color}}/>
            <h2 id='title' style={{ color: color}}>{title}</h2>
        <div className='line-spacer' style={{ backgroundColor: color}}/>
    </div>
  )
}

export default SectionHeader