import React, { useEffect, useState } from 'react'
import "./styles.css"
import { motion, useScroll } from "framer-motion"

function AnimatedBanner() {
    const {scrollY}  = useScroll();
    const [ motionValue, setMotionValue] = useState(0);

    useEffect(() =>{
        scrollY.onChange(val => {
            if (val >= 650) {
                setMotionValue(650);
            } else {
                setMotionValue(val);
            }
        })
    },[scrollY])

    // useEffect(() => {
    //     scrollY.onChange(val=> {
    //         const maxWidth = window.innerWidth();
    //         console.log(maxWidth);
    //         if (val >= maxWidth){
    //             return null
    //         } else {
    //             setMotionValue(val);
    //         }
    //     })
    // },[scrollY])


  return (
    <div className={'banner-image-container'}>
        <img src={require("../../../../assets/Banner-Background.png")} alt="DDNL Banner" id='banner' className='image-fit'/>
        <div className='first-people-container'>
            <img src={require('../../../../assets/man-and-woman.png')} alt='' className='image-fit'/>
        </div>
        <div className='second-people-container'>
            <img src={require('../../../../assets/man.png')} alt='' className='image-fit'/>
            <img src={require('../../../../assets/woman.png')} alt='' className='image-fit'/>
        </div>
        <motion.div className='car-container' style={{ marginLeft: motionValue}}>
            <img src={require('../../../../assets/ddnl-car.png')} alt='car' className='image-fit'/>
        </motion.div>
    </div>
  )
}

export default AnimatedBanner