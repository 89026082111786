import { createTheme } from "@mui/material";
import { green } from "@mui/material/colors";

export const theme = (theme) => createTheme({
    ...theme,
    palette: {
        ...theme.palette,
        primary: {
            main: green[500],
        }
    },
    typography: {
        ...theme.typography,
        fontFamily: 'Montserrat Alternates'
    },
});
