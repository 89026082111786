import React from 'react'
import "./styles.css"
import { FaArrowRight } from "react-icons/fa";

function MissionList({ index, title, body }) {
  return (
    <div className='missionlist-container'>
        <div className='missionlist-title'>
            <p><b>{index}</b>{title}</p>
            <FaArrowRight  size={25} style={{ paddingTop: 10}}/>
        </div>
        <p className='body-text'>{body}</p>
    </div>
  )
}

export default MissionList