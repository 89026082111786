import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from "yup"
import {
    Avatar,
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    Alert,
    Switch,
    Button,
    Card,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../api/firebase';
import "./styles.css"
import { Cancel, CheckCircle } from '@mui/icons-material';

const EditMemberForm = ({ userId, fullName, email, onClickCancel, banned = false }) => {
    const [ alert, setAlert] = useState({
        status: false,
        severity: ""
    });
    const formik = useFormik({
        initialValues: {
            fullName: fullName,
            email: email,
            region: "St. John's/ Mt. Pearl",
            banned: banned,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
            fullName: Yup.string().max(255).required("First name is required"),
            region: Yup.string().max(255).optional()
        }),
        onSubmit: async (values, helpers) => {
            try {
                await updateDoc(doc(db, "drivers", userId ),{
                    email: values.email,
                    banned: values.banned,
                });
                setAlert({
                    status: true,
                    severity: "success"
                })
            } catch (error) {
                setAlert({
                    status: true,
                    severity: "error"
                })
            }
        }
    })

    function handleClickCancel(event){
        onClickCancel(event);
        setAlert({
            status: false,
            severity: ""
        })
    }
  return (
    <form>
        <Container p={4}>
            <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} marginY={2}>
                <Box display={"flex"} gap={2} p={1}>
                    <Avatar
                        sx={{
                            bgcolor: green[600],
                            height: { xs: 50, sm: 80 },
                            width: { xs: 50, sm: 80 }
                        }}
                        alt={fullName}
                        />
                    <Box>
                        <Typography variant='h6'>
                            {fullName}
                        </Typography>
                        <Typography>
                            email: {email}
                        </Typography>
                    </Box>
                </Box>
                <Alert
                    sx={{
                        height: "fit-content",
                        borderRadius: 5
                    }}
                    icon={false}
                    variant='filled'
                    severity={ formik.values.banned ? "error" : "success"}
                >
                    { formik.values.banned ? "Banned" : "Active"}
                </Alert>
            </Box>
            <Container sx={{ marginBottom: 5}}>
                <Card
                    variant='outlined'
                    sx={{
                        borderRadius: 10
                    }}
                >
                    {alert.status ?
                        <Box
                            height={{ xs: 200, sm:400 }}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            fontSize={80}
                        >
                            <Typography
                                variant='h4'
                                color={alert.severity === "success" ? 'black' : red[800]}
                            >
                                {alert.severity === "success" ? "Driver Added" : "Error"}
                            </Typography>
                            {alert.severity &&
                                <Typography variant='body1'>
                                    Error occured trying to update driver, Wait a few minutes and try again later
                                </Typography>
                            }
                            {alert.severity === "success" ?
                                <Button sx={{ fontSize: 80 }} onClick={handleClickCancel}>
                                    <CheckCircle
                                        color={alert.severity}
                                        fontSize={'inherit'}
                                        className='checkbox'
                                    />
                                </Button>
                                :
                                <Button sx={{ fontSize: 80 }} onClick={handleClickCancel}>
                                    <Cancel
                                        color={alert.severity}
                                        fontSize={'inherit'}
                                        className='checkbox'
                                    />
                                </Button>
                            }
                        </Box>
                    :
                        <Grid container spacing={1} marginTop={2} padding={3}
                            sx={{
                                borderColor: 'lightgray',
                                boxShadow:'initial'
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography fontWeight={600} fontSize={20} marginBottom={2}>
                                    Edit User
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    error={!!(formik.touched.fullName && formik.errors.fullName)}
                                    fullWidth
                                    helperText={formik.touched.fullName && formik.errors.fullName}
                                    label="Full Name"
                                    name="fullName"
                                    color='primary'
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type='text'
                                    value={formik.values.fullName}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    label="Email Address"
                                    name="email"
                                    color='primary'
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type='email'
                                    value={formik.values.email}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    error={!!(formik.touched.region && formik.errors.region)}
                                    fullWidth
                                    name= "region"
                                    >
                                    <InputLabel htmlFor='res-region'>Region</InputLabel>
                                    <Select
                                        aria-label='region-input'
                                        value={formik.values.region}
                                        onChange={formik.handleChange}
                                        label="Region"
                                        id='res-region'
                                        name='region'
                                        >
                                        <MenuItem value={"St.Johns/ Mt. Pearl"}>St. John's/Mt. Pearl</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item marginTop={5} xs={12}>
                                <Box display={'flex'} justifyContent={"space-between"}>
                                    <Box>
                                        <Typography fontSize={20} fontWeight={600}>
                                            Banned
                                        </Typography>
                                        <Typography variant='subtitle1'>
                                            Apply temporary ban on account
                                        </Typography>
                                    </Box>
                                    <Switch
                                        name='banned'
                                        checked={formik.values.banned}
                                        onChange={formik.handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item display={'flex'} alignItems={'center'} gap={5} marginTop={2} xs={12}>
                                <Button type='submit' variant='contained' sx={{ alignSelf: "center", color: "white", fontWeight: 600}}>
                                    Update
                                </Button>
                                <Button onClick={handleClickCancel} variant='text' color='info' sx={{ alignSelf: "center", fontWeight: "600", color: "black"}}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Card>
            </Container>
        </Container>
    </form>
  )
}

export default EditMemberForm