import React from "react";
import './App.css';
import Routing from "./routes/Routes";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AuthProvider } from "./providers/AuthProvider";
import { DataProvider } from "./providers/DataProvider";

Bugsnag.start({
  apiKey: '1afa71915e03040755bba6b8aa782351',
  plugins: [new BugsnagPluginReact()]
})

function App() {
  return (
      <AuthProvider>
        <DataProvider>
          <Routing />
        </DataProvider>
      </AuthProvider>
  );
}

export default App;
