import React from 'react'
import './styles.css'
import { Box } from '@mui/material'

function Logo({ size, onClick }) {
  return (
    <Box onClick={onClick} className='logo-container' sx={{ alignSelf: "center", ":hover": { cursor: "pointer"}}}>
        <img src={require('../../../assets/logo2.png')} alt='logo' style={{ height: size, objectFit: size ? 'cover' : null }}/>
    </Box>
  )
}

export default Logo