import { AddRounded } from '@mui/icons-material'
import { Container, Button, Typography, Dialog, Fade } from '@mui/material'
import React, { useState } from 'react'
import MembersTable from '../components/tables/MembersTable'
import { useData } from '../../../providers/DataProvider'
import MemberForm from '../components/form/MemberForm'
import EditMemberForm from '../components/form/EditMemberForm'

const FadeOut = React.forwardRef(function FadeOut(props, ref) {
    return <Fade ref={ref} {...props}  />
})

const Members = () => {
    const { drivers, setSelectedMember, selectedMember } = useData();
    const [addFormVisible, setAddFormVisible] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    function handleClickEdit (event, item) {
        event.preventDefault();
        setSelectedMember(item);
        setEditDialog(true);
    }
  return (
    <Container sx={{ display: "flex", flexDirection: "column"}} maxWidth='xl'>
            <Typography variant='h4' fontWeight={'600'}>
                Members
            </Typography>
            <Button
                variant='contained'
                startIcon={<AddRounded fontSize='large' color='white' sx={{ color: "white" }} />}
                disableElevation
                onClick={() => setAddFormVisible(true)}
                sx={{
                    bgcolor: "green",
                    paddingX: 2,
                    color: 'white',
                    alignSelf: "flex-end",
                    fontWeight: "600",
                    ":hover": {
                        bgcolor: "darkgreen"
                    }
                }}
            >
                Add Driver
            </Button>
            <MembersTable data={drivers} onClickEdit={handleClickEdit}/>
            <Dialog
                maxWidth="xl"
                fullWidth
                sx={{
                    width: { md: `calc(100% - 240px)`},
                    marginLeft: { md: `240px` },
                }}
                open={addFormVisible}
                onClose={() => setAddFormVisible(false)}
                TransitionComponent={FadeOut}
            >
                <MemberForm onClickCancel={() => setAddFormVisible(false)} />
            </Dialog>
            <Dialog
                maxWidth="xl"
                fullWidth
                sx={{
                    width: { md: `calc(100% - 240px)`},
                    marginLeft: { md: `240px` },
                }}
                open={editDialog}
                onClose={() => setEditDialog(false)}
                TransitionComponent={FadeOut}
            >
                <EditMemberForm
                    userId={selectedMember?.driverId}
                    fullName={selectedMember?.name}
                    email={""}
                    onClickCancel={() => setEditDialog(false)}
                />
            </Dialog>
    </Container>
  )
}

export default Members