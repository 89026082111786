import React from 'react'
import "./styles.css"
import { Box } from '@mui/material'

const OnlinePulse = ({sx}) => {
  return (
    <Box sx={sx} className='circle'/>
  )
}

export default OnlinePulse