/* eslint-disable no-unused-vars */

import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Tooltip, Box, TablePagination } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import OnlinePulse from '../misc/OnlinePulse';
import { ArrowForward, Edit } from '@mui/icons-material';

const headers = ["Name","Type","Location","Online","Actions"];

const DEFAULT_ROWS_PER_PAGE = 8;
const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = "name";

const MembersTable = ({ data, onClickDetails, onClickEdit }) => {
    const [order, setOrder] = useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [visibleData, setVisibleData] = useState(data);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

      function handleChangeTab(event, newValue) {
        setTab(newValue);
      }

      const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
        setVisibleData(() =>
            data.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
                ).sort(
                    getComparator(order,orderBy)
                    )
        );

      },
       // eslint-disable-next-line react-hooks/exhaustive-deps
      [order, orderBy, rowsPerPage]
      );

      const handleChangeRowsPerPage = useCallback(
        (event) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);
            setPage(0);

            setVisibleData(() =>
                data.slice(
                    0 * updatedRowsPerPage,
                    0 * updatedRowsPerPage + updatedRowsPerPage,
                    ).sort(
                        getComparator(order,orderBy)
                        )
            );
        },
         // eslint-disable-next-line react-hooks/exhaustive-deps
        [order, orderBy],
      );

      useEffect(() => {
        setVisibleData(() =>
            data.slice(0,0 + DEFAULT_ROWS_PER_PAGE).sort(
                getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY))
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

  return (
    <Box>
        <TableContainer
            sx={{ marginTop: 10, borderRadius: 7 }}
            component={Paper}
        >
            <Table size='small' sx={{ minWidth: 850}}>
                <TableHead>
                    <TableRow>
                        <Tabs value={tab} onChange={handleChangeTab}>
                            <Tab value={0} label="All" />
                            <Tab value={1} label="Members" />
                            <Tab value={2} label="Drivers" />
                        </Tabs>
                    </TableRow>
                    <TableRow sx={{ bgcolor: "#f5f5f5", paddingY: 2, marginY: 2 }}>
                        {headers.map((item,index) => (
                            <TableCell key={index} sx={{ textTransform: "uppercase", fontWeight: 600, borderColor: "#e5e5e5"}}>
                                {item}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleData.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item?.name ? item?.name : item?.displayName }</TableCell>
                            <TableCell>Driver</TableCell>
                            <TableCell>St. John's/ Mt.Pearl</TableCell>
                            <TableCell align='right'>
                                <OnlinePulse sx={{
                                    bgcolor: item.online ? "green" : "red",
                                    height: 10,
                                    width: 10,
                                    alignSelf: "center",
                                    animation: item.online ? "pulse 2s linear" : "offline 2s infinite",
                                    marginLeft: 2.5
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Edit">
                                    <IconButton onClick={(event) => onClickEdit(event,item)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Details">
                                    <IconButton onClick={onClickDetails}>
                                        <ArrowForward/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            component={'div'}
            rowsPerPageOptions={[8,16,24]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
  )
}

export default MembersTable