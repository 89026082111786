import React, { useState, useEffect, useCallback } from 'react'
import './styles.css'
import NavBarItems from './NavBarItems';


const pages = [{
    id: 1,
    title: "Vision",
    route: "vision"
},
{
    id: 2,
    title: "About Us",
    route: "about"
},
{
    id: 3,
    title: "Community",
    route: "community"
},
{
    id: 4,
    title: "Contact Us",
    route: "contactUs"
}
]
const initialWidth = window.innerWidth;

function NavBar({visible, onClickItem, setMenuClicked, handleClick, onClickSignIn}) {
    // const [ itemPressed, setItemPressed ] = useState('');
    const [width, setWidth] = useState(initialWidth)
    // const SignInButton = useCallback(() => {
    //     <button>SignIn</button>
    // },[])

    function handleClickSignIn(event) {
        event.preventDefault();
        onClickSignIn();
    }

    const CloseBUtton = useCallback(() => {
        if (width <= 768){
            return <button className='close-button' onClick={handleClick}>x</button>
        } else {
            return null;
        }
    },[handleClick, width])

    const SignInButton = useCallback(() => {
        if( width <= 768) {
            return <button onClick={handleClickSignIn} className='signIn-container'>sign in</button>
        } else {
            return null;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[width])

    useEffect(() => {
        //Update width value when browser is being resized
        window.addEventListener('resize', (event) => {
            setWidth(window.innerWidth);
        });
        if(visible.value && width <= 768){
            document.body.style.overflow = 'hidden' ;
            window.scrollTo(0,0);
        } else {
            document.body.style.overflow = 'unset';
        }
    },[visible, width]);

  return (
    <>
        { visible.value &&
            <div className={`navbar-container ${visible.style}`}>
                <CloseBUtton />
                <ul>
                  {pages.map(page => <NavBarItems id={page.id} title={page.title} visible={true} onClick={onClickItem} route={page.route} key={page.id} setMenuClicked={setMenuClicked} />)}
                </ul>
                <SignInButton />
            </div>
        }
    </>
  )
}

export default NavBar