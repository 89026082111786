import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Box } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import "./styles.css"
import { red } from '@mui/material/colors';

const DRIVER_URL = process.env.REACT_APP_ADD_DRIVER_URL;

const MemberForm = ({ type = "Add", initalValues, onClickCancel }) => {
    const [alert, setAlert] = useState({
        status: false,
        severity:""
    })
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email:"",
            region:"",
            password:"",
            type: "DD",
            admin: "DD"
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Must be a valid email').max(255).required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
            firstName: Yup.string().max(255).required("First name is required"),
            lastName: Yup.string().max(255).required("Last Name is required"),
        }),
        onSubmit : async (values, helpers) => {
            try {
                await axios.post(DRIVER_URL,{
                    admin: values.admin,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    type: values.type,
                });
                setAlert({
                    status: true,
                    severity:"success"
                })
            } catch (error) {
                setAlert({
                    status: true,
                    severity: "error"
                })
            }
        }
    });

    function handleClickCancel(event) {
        onClickCancel(event);
        setAlert({
            status: false,
            severity: ""
        });
    }
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
        {alert.status ?
            <Box
                height={{ xs: 200, sm:400 }}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                fontSize={80}
            >
                <Typography
                    variant='h4'
                    color={alert.severity === "success" ? 'black' : red[800]}
                >
                    {alert.severity === "success" ? "Driver Added" : "Error"}
                </Typography>
                {alert.severity &&
                    <Typography variant='body1'>
                        Error occured trying to add driver, Wait a few minutes and try again later
                    </Typography>
                }
                {alert.severity === "success" ?
                    <CheckCircle color={alert.severity} fontSize={'inherit'} className='checkbox'/>
                    :
                    <Cancel
                        color={alert.severity}
                        fontSize={'inherit'}
                        className='checkbox'
                    />
                }
            </Box> :
            <>
                <Typography variant='h5' alignSelf={"center"} textAlign={'center'} bgcolor={"lightgray"} paddingY={1}>
                        Add Driver
                </Typography>
                <Grid container spacing={3} marginTop={2} paddingX={5} marginBottom={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={!!(formik.touched.firstName && formik.errors.firstName)}
                            fullWidth
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            label="First Name"
                            name="firstName"
                            color='primary'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type='text'
                            value={formik.values.firstName} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={!!(formik.touched.lastName && formik.errors.lastName)}
                            fullWidth
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            label="Last Name"
                            name="lastName"
                            color='primary'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type='text'
                            value={formik.values.lastName} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={!!(formik.touched.email && formik.errors.email)}
                            fullWidth
                            helperText={formik.touched.email && formik.errors.email}
                            label="Email Address"
                            name="email"
                            color='primary'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type='email'
                            value={formik.values.email} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl
                            error={!!(formik.touched.region && formik.errors.region)}
                            fullWidth
                            name="region"
                        >
                            <InputLabel htmlFor='res-region'>Region</InputLabel>
                            <Select
                                aria-label='region-input'
                                value={formik.values.region}
                                onChange={formik.handleChange}
                                label="Region"
                                id='res-region'
                                name='region'
                            >
                                <MenuItem value={"St.Johns/ Mt. Pearl"}>St. John's/Mt. Pearl</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item alignSelf={'center'} xs={12} md={6}>
                        <TextField
                            error={!!(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            label="Temporary Password"
                            name="password"
                            color='primary'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type='text'
                            value={formik.values.password} />
                    </Grid>
                    <Grid container marginTop={2} paddingX={3}>
                        <Grid item alignItems={'center'} spacing={3} gap={5} justifyContent={'center'} xs={4.5} sm={3}>
                            <Button type='submit' variant='contained' sx={{ alignSelf: "center", color: "white", fontWeight: 600 }}>
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item alignItems={'center'} spacing={3} gap={5} justifyContent={'center'} xs={4.5} sm={3}>
                            <Button onClick={handleClickCancel} variant='text' color='info' sx={{ alignSelf: "center", fontWeight: "600", color: "black" }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        }
    </form>
  )
}

export default MemberForm