import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { Icon } from '@mui/material'
import { Group, Map, HomeRounded } from '@mui/icons-material'

const DrawerMenuItem = ({ index, activeIndex, icon, label="Home", sx, onClick }) => {
    function handleClickRoute(e) {
        e.preventDefault();
        onClick(label, index);
    }
  return (
    <ListItem disablePadding>
        <ListItemButton
            selected={activeIndex === index}
            sx={[
                {
                    borderRadius: 2,
                    paddingY: 0.25 ,
                    ":hover": { bgcolor: "#e5e5e520"},
                    marginY: 0,
                    "&.Mui-selected": {
                        bgcolor: "#e5e5e520"
                    }
                },
                sx
            ]}
                    onClick={handleClickRoute}>
            <ListItemIcon  sx={{ color: activeIndex === index ? "#2fb63f" :'lightgray', "&.Mui-selected": {
            } }}>
                {icon && label === "Home" ? <Icon component={HomeRounded}/> : label === "Members" ? <Icon component={Group} /> : label === "Map" ? <Icon component={Map} /> : <Icon component={HomeRounded}/>}
            </ListItemIcon>
            <ListItemText
                primary={
                    <>
                        <Typography sx={{
                            color: activeIndex === index ? "white" : 'lightgray',
                            fontWeight: '600'
                        }}>
                            {label}
                        </Typography>
                    </>
                }
             />
        </ListItemButton>
    </ListItem>
  )
}

export default DrawerMenuItem