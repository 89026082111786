import { AppBar, CssBaseline, IconButton, Stack} from '@mui/material'
import React, { useState } from 'react'
import { FaBars, FaBell, FaSearch, FaUserFriends } from 'react-icons/fa'
import AccountMenu from './AccountMenu';
import { auth } from '../../../api/firebase';

const Header = ({ drawerWidth, onClickMenu }) => {
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickAvatar = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (e) => {
        setAnchorEl(null);
    }

    async function handleClickSignOut(e) {
        e.preventDefault();
        setLoading(true);
       await auth.signOut()
    }

  return (
    <>
        <CssBaseline />
        <AppBar
            color='transparent'
            elevation={0}
            sx={{
                width: {md: `calc(100% - ${drawerWidth}px)`},
                paddingY: 0 ,
                ml: {md: `${drawerWidth}px`},
                backdropFilter: 'blur(10px)',
            }}
        >
            <Stack direction={"row"} sx={{ paddingY: 1 , marginX: 4, justifyContent: "space-between"}} >
                <Stack direction={'row'} spacing={{xs:3, md:0}}>
                    <IconButton sx={{ display: { xs: 'block', md: 'none' }}} onClick={onClickMenu}>
                        <FaBars />
                    </IconButton>
                    <IconButton sx={{ display: 'block'}}>
                        <FaSearch />
                    </IconButton>
                </Stack>
                <Stack direction={"row"} spacing={3}>
                    <IconButton>
                        <FaBell/>
                    </IconButton>
                    <IconButton>
                        <FaUserFriends/>
                    </IconButton>
                    <AccountMenu
                        onClickSignOut={handleClickSignOut}
                        onCloseMenu={handleClose}
                        open={open}
                        onClickMenu={handleClose}
                        onClickAvatar={handleClickAvatar}
                        anchorEl={anchorEl}
                    />
                </Stack>
            </Stack>
        </AppBar>
    </>
  )
}

export default Header