import React, { useEffect, useState } from 'react'
import HeaderContent from './HeaderContent'
import Logo from './Logo'
import './styles.css'

const initialWidth = window.innerWidth;

function Header({ handleMenuClick, isMenuActive}) {
  const [width, setWidth] = useState(initialWidth);
  const [isFixed, setIsFixed] = useState('');
  useEffect(()=>{
    window.addEventListener('resize',() => {
      setWidth(window.innerWidth);
    })
    if( isMenuActive.value === true && width < 768){
      setIsFixed('fixed');
    } else {
      setIsFixed('');
    }
  },[isMenuActive.value, width])

  return (
    <div className={`header-container ${isFixed}`}>
        <Logo/>
        <HeaderContent handleMenuClick={handleMenuClick} isMenuActive={isMenuActive}/>
    </div>
  )
}

export default Header