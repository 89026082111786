import React from 'react'
import "./styles.css"
import { FaCopyright } from "react-icons/fa"

function Footer() {
  return (
    <footer className='footer-container'>
      <div className='footer-content'>
        <img src={require('../../../assets/logo2.png')} alt='DDNL Company Logo'/>
        <p><FaCopyright/> Designated Drivers of NL</p>
      </div>
    </footer>
  )
}

export default Footer;