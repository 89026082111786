import { ArrowLeft } from '@mui/icons-material'
import { Box, Container, Typography, Button } from '@mui/material'
import React from 'react'

const Page = () => {
  return (
    <Box
        component="main"
        sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
        }}
    >
        <Container maxWidth="md">
            <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}
            >
                <Box
                    sx={{
                    mb: 3,
                    textAlign: 'center'
                    }}
                >
                    <img
                    alt="Under development"
                    src={require("../../assets/error-404.png")}
                    style={{
                        display: 'inline-block',
                        maxWidth: '100%',
                        width: 400
                    }}
                    />
                </Box>
                <Typography
                    align="center"
                    sx={{ mb: 3 }}
                    variant="h3"
                >
                    404: The page you are looking for isn’t here
                </Typography>
                <Typography
                    align="center"
                    color="text.secondary"
                    variant="body1"
                >
                    You either tried some shady route or you came here by mistake.
                    Whichever it is, try using the navigation
                </Typography>
                <Button
                    href="/"
                    startIcon={(
                        <ArrowLeft />
                    )}
                    sx={{ mt: 3 }}
                    variant="contained"
                >
                    Go back Home
                </Button>
            </Box>
        </Container>
    </Box>
  )
}

export default Page