import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import './styles.css'

function SocialIcon() {
  return (
    <div id='button' className='social-container'>
        <a href='https://www.facebook.com/groups/762021200587969' target="_blank" rel="noopener noreferrer"><FaFacebook size={40} color={'#2fb63f'}/></a>
        <FaTwitter size={40} color={'#2fb63f'}/>
        <FaInstagram size={40} color={'#2fb63f'}/>
    </div>
  )
}

export default SocialIcon