import React from 'react'
import { motion } from 'framer-motion'
import { FaFacebookF } from 'react-icons/fa';
import './styles.css'

  const facebookVariants = {
    hidden: {
        opacity: 0,
        scale: 0.5
    },
    onscreen: {
        opacity:1,
        scale: 1,
        transition: {
            duration:0.6
        }
    }
}

function SpringImage() {
  return (
    <motion.div className='story-image-container'>
        <motion.img src={require('./../../../assets/ddnl-facebook.jpeg')}/>
        <motion.div variants={facebookVariants} className='facebook-icon-container'>
            <a href='https://www.facebook.com/groups/762021200587969' target="_blank" rel="noopener noreferrer"><FaFacebookF size={50} style={{ alignSelf: 'flex-end'}} /></a>
        </motion.div>
    </motion.div>
  )
}

export default SpringImage