import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../components/pages/HomePage";
import { Login } from "../components/pages/Login";
import { useAuthState } from "../providers/AuthProvider";
import AdminApp from "../modules/admin/App";
import ForgotPassword from "../components/pages/ForgotPassword";
// import { Backdrop, CircularProgress } from "@mui/material";
import Page from "../components/pages/404";

export default function Routing() {
    const { role, isSignedIn } = useAuthState();
    return (
            <Routes>
                {
                    isSignedIn && ["admin","moderator"].includes(role) ?
                    <>
                        <Route path="/" element={<AdminApp />} />
                        <Route path="*" element={<Page />} />
                    </>
                 :
                    <>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/login/reset" element={<ForgotPassword />} />
                        <Route path="*" element={<Page />} />
                    </>
                 }
            </Routes>
    )
}