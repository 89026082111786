import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [isSignedIn, setIssignedIn] = useState(null);
    const [role, setRole] = useState();
    const [user, setUser] = useState();
    const [initializing, setInitializing] = useState(false);

    useEffect(() => {
        function onAuthStateChanged(user){
            setInitializing(true);
            if (user) {
                setUser(user);
                setIssignedIn(true)
            } else {
                setUser(null);
                setIssignedIn(false);
                setInitializing(false);
            }
        }

        const subscribe = auth.onAuthStateChanged(onAuthStateChanged);
        return subscribe;
    },[]);

    useEffect(() =>{
        if (!user) {
            return;
        }
        const adminsRef = doc(db,"admins", user.uid);
        const moderatorRef = doc(db,"moderators",user.uid);
        (async () => {
            try {
                let doc = await getDoc(adminsRef);
                if (doc.exists) {
                    setRole("admin");
                } else {
                    let doc = await getDoc(moderatorRef);
                    if (doc.exists) {
                        setRole("moderator");
                    }
                }
                setInitializing(false);
            } catch (error) {
                console.log(error);
                setRole("invalid");
                setInitializing(false);
            }
        })();
    },[user])
    return (
        <AuthContext.Provider value={{ role, isSignedIn, initializing}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthState = () => useContext(AuthContext);
